@charset "utf-8";

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, dialog, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block
}

/* ios默认文本框阴影 */
input[type=text], textarea {
  -webkit-appearance: none;
}

/* 低版本安卓文本框层级问题 */
input:focus {
  -webkit-user-modify: read-write-plaintext-only;
}

ol, ul, li, menu {
  list-style: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

html, body {
  width: 100%;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  line-height: 1;
  color: #303030;
}

html {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: 100%
}

body {
  background-color: #f6f9fa;
}

body.modal-open {
  position: fixed;
  width: 100%;
}

a, button, input {
  outline: 0 none;
  text-decoration: none
}

img {
  border: 0 none;
  vertical-align: bottom;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
}


