$brand-primary:#2A83E1;

.mt-40{
  margin-top: 40px;
}

body ,*{
  margin: 0;
  padding: 0;
}
.Yep-action-sheet{
  padding-bottom:env(safe-area-inset-bottom);
  padding-bottom:constant(safe-area-inset-bottom);
  .Yep-action-sheet-cancel span {
    color:$brand-primary;
  }
}

.Yep-icon-arrow-right{
  color: #C7C7CC;
}


.Yep-dialog-button-group-h {
  .Yep-dialog-button:first-child{
    color: $brand-primary;
  }
}

.Yep-list-item img.checkedImg{
  display: block;
  width: 34px;
  height: 24px;
}
//控制Toast的最大宽度，单行最多14个中文字
.Yep-toast {
  .Yep-toast-notice{
    max-width: 528px;
  }
}

.aaa{
  flex-direction: column;
}

.pt90 {
  padding-top: 90px;
}

.pt100 {
  padding-top: 100px;
}

.pt190 {
  padding-top: 190px;
}

.Yep-carousel__page-bullet.active {
  width: 14px!important;
}

.Yep-carousel__page {
  bottom: 40px!important;
}
