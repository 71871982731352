.loadingContainer {
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadingImg {
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
